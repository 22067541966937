@font-face {
  src: url(../assets/fonts/MazzardH-ExtraLight.otf);
  font-family: maz;
}
@font-face {
  src: url(../assets/fonts/MazzardH-ExtraBold.otf);
  font-family: mazBold;
}

.productionStatistics {
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  width: 10em;
  height: 4.5em;
  padding: 3.5rem 2.5rem;
  border-radius: 15px;
  line-height: 110%;
  font-size: 3em;
  font-weight: 600;

  border: 1px solid rgba(1, 40, 128, 0);
  background-image: linear-gradient(-180deg, #0092d7 0%, #14355b 100%);
  box-shadow: 0 1rem 1.25rem 0 rgba(59, 61, 67, 0.5),
    0 -0.25rem 1.5rem rgba(17, 44, 78, 1) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(22, 55, 95, 1) inset;
}
@media screen and (max-width: 600px) {
  .productionStatistics,
  .clr {
    width: 9em;
  }
}
.productionStatistics span,
.clr span {
  color: transparent;
  background-image: linear-gradient(0deg, #a5a5a5 0%, #fefafd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(291, 5%, 28%, 1));
}

.productionStatistics div {
  font-size: 1.3rem;
  color: #f0de3a;
  position: absolute;
  top: 10px;
  left: 50px;
  animation: pulsate 3s infinite;
  -webkit-animation: pulsate 3s infinite; /* Safari and Chrome */
}
@keyframes pulsate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@-webkit-keyframes pulsate {
  /* Safari and Chrome */
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.productionStatistics::before,
.clr::before {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.productionStatistics::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.myAgent,
.clr {
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  width: 10em;
  max-width: 10em;
  height: 4.5em;
  padding: 3.5rem 2.5rem;
  border-radius: 15px;
  line-height: 110%;
  font-size: 3em;
  font-weight: 600;

  border: 1px solid rgba(1, 40, 128, 0);
  background-image: linear-gradient(-180deg, #36608a 0%, rgb(12, 33, 58) 100%);
  box-shadow: 0 1rem 1.25rem 0 rgba(59, 61, 67, 0.5),
    0 -0.25rem 1.5rem rgba(17, 44, 78, 1) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(22, 55, 95, 1) inset;
}
@media screen and (max-width: 600px) {
  .myAgent,
  .clr {
    width: 9em;
  }
}
.myAgent span,
.clr span {
  color: transparent;
  background-image: linear-gradient(0deg, #a5a5a5 0%, #fefafd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(291, 5%, 28%, 1));
}
.myAgent::before,
.clr::before {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.myAgent::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.agencyOverview {
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  width: 10em;
  height: 4.5em;
  padding: 3.5rem 2.5rem;
  border-radius: 15px;
  line-height: 110%;
  font-size: 3em;
  font-weight: 600;

  border: 1px solid rgba(1, 40, 128, 0);
  background-image: linear-gradient(-180deg, #969495 0%, #14355b 100%);
  box-shadow: 0 1rem 1.25rem 0 rgba(59, 61, 67, 0.5),
    0 -0.25rem 1.5rem rgba(17, 44, 78, 1) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(22, 55, 95, 1) inset;
}
@media screen and (max-width: 600px) {
  .agencyOverview {
    width: 9em;
  }
}
.agencyOverview span {
  color: transparent;
  background-image: linear-gradient(0deg, #a5a5a5 0%, #fefafd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(291, 5%, 28%, 1));
}
.agencyOverview::before {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.agencyOverview::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.contractRequest {
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  width: 10em;
  height: 4.5em;
  padding: 3.5rem 2.5rem;
  border-radius: 15px;
  line-height: 110%;
  font-size: 3em;
  font-weight: 600;

  border: 1px solid rgba(1, 40, 128, 0);
  background-image: linear-gradient(
    -190deg,
    rgb(144, 173, 192) 0%,
    #0a3e79 85%
  );
  box-shadow: 0 1rem 1.25rem 0 rgba(59, 61, 67, 0.5),
    0 -0.25rem 1.5rem rgba(17, 44, 78, 1) inset,
    0 0.25rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(22, 55, 95, 1) inset;
}

.disabledContractRequest {
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  color: #eee;
  position: relative;
  width: 10em;
  height: 4.5em;
  padding: 3.5rem 2.5rem;
  border-radius: 15px;
  background-clip: border-box;
  line-height: 110%;
  font-size: 3em;
  font-weight: 600;
  outline: none;
  border: 1px solid rgba(1, 40, 128, 0);
  background-image: linear-gradient(-190deg, rgb(47, 48, 49) 0%, #0a3e79 85%);
  box-shadow: 0 1rem 1.25rem 0 rgba(59, 61, 67, 0.5),
    0 -0.25rem 1.5rem rgba(17, 44, 78, 1) inset,
    0 0.25rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(22, 55, 95, 1) inset;
}

.disabledContractRequest::before {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.disabledContractRequest::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  border-radius: 15px;
  opacity: 0.5;
}

.disabledContractRequest:focus,
.disabledContractRequest:active,
.disabledContractRequestHome:visited {
  outline: none;
}

@media screen and (max-width: 600px) {
  .contractRequest {
    width: 9.5em;
    font-size: 3em;
  }
}
.contractRequest span {
  color: transparent;
  background-image: linear-gradient(0deg, #acacac 0%, #fefafd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(291, 5%, 28%, 1));
}
.contractRequest::before {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.6;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.contractRequest::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.agencyName {
  text-transform: uppercase;
  font-family: verdana;
  font-size: 4em;
  color: white;
  font-weight: 700;
  color: #f5f5f5;
  text-shadow: 1px 1px 1px #919191, 1px 2px 1px #919191, 1px 3px 1px #919191,
    1px 4px 1px #919191, 1px 5px 1px #919191, 1px 6px 1px #919191,
    1px 7px 1px #919191, 1px 8px 1px #919191, 1px 9px 1px #919191,
    1px 10px 1px #919191, 1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);
}
.agencyName2 {
  font-size: 4em;
  color: white;
  font-weight: bold;
  font-family: Helvetica;
  text-shadow: 0 1px 0 #ccc, 0 2px 0 #c9c9c9, 0 3px 0 #bbb, 0 4px 0 #b9b9b9,
    0 5px 0 #aaa, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}
@font-face {
  font-family: "ChunkFiveRegular";
  src: url("https://www.midwinter-dg.com/blog_demos/css-text-shadows/fonts/chunkfive-webfont.eot");
  src: url("https://www.midwinter-dg.com/blog_demos/css-text-shadows/fonts/chunkfive-webfont.eot?iefix")
      format("eot"),
    url("https://www.midwinter-dg.com/blog_demos/css-text-shadows/fonts/chunkfive-webfont.woff")
      format("woff"),
    url("https://www.midwinter-dg.com/blog_demos/css-text-shadows/fonts/chunkfive-webfont.ttf")
      format("truetype"),
    url("https://www.midwinter-dg.com/blog_demos/css-text-shadows/fonts/chunkfive-webfont.svg#webfont6hibqX7I")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

/* text-shadow styles */

.agencyName3 {
  color: #fff;
  text-shadow: 0px 1px 0px #999, 0px 2px 0px #888, 0px 3px 0px #777,
    0px 4px 0px #666, 0px 5px 0px #555, 0px 6px 0px #444, 0px 7px 0px #333,
    0px 8px 7px #001135;
  font: 4em "ChunkFiveRegular";
}
