html > body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #9e9e9e;
}

#root,
.content {
  min-height: stretch;
}

div,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "HelveticaNeueLTStd", "Helvetica Neue LT Std",
    "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica,
    Arial, "Lucida Grande", sans-serif;
  font-weight: 300;
}

.content {
  min-height: calc(100vh - 45px);
}

.footer {
  height: 60px;
  /* margin-bottom: -10px; */
  /* background: linear-gradient(to bottom right, white, #0e76bc) !important; */
}

.poweredBy {
  margin-bottom: 10;
  font-style: normal;
  font-variant: normal;
  font-weight: bold;
  font-size: calc(12px + (24 - 12) * ((100vw - 250px) / (4000 - 250)));
  line-height: calc(1.3em + (1.5 - 1.2) * ((100vw - 250px) / (3000 - 250)));
  color: "#eee";
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}
input[type="file"] {
  width: 100%;
}
