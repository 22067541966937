.myClass {
  height: "auto";
  width: 100%;
}

.contract {
  height: 315px;
  width: 100%;
}

.contract .super-app-theme--header {
  background-color: rgba(184, 73, 255, 0.8);
}

.production {
  height: 315px;
  width: 100%;
}

.production .super-app-theme--header {
  background-color: rgba(215, 139, 255, 0.8);
}

.readyToSell {
  height: 315px;
  width: 100%;
}

.readyToSell .super-app-theme--header {
  background-color: rgba(27, 133, 243, 0.8);
}

.dataGridDefault {
  height: 960px;
  width: 100%;
}

.dataGridDefault .super-app-theme--header {
  background-color: rgba(27, 133, 243, 0.8);
}
