.productionStatisticsHome {
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  width: 11em;
  height: 4.5em;
  padding: 3rem 2rem;
  border-radius: 15px;
  line-height: 110%;
  font-size: 2.25em;
  font-weight: 600;

  border: 1px solid rgba(1, 40, 128, 0);
  background-image: linear-gradient(-180deg, #0092d7 0%, #14355b 100%);
  box-shadow: 0 1rem 1.25rem 0 rgba(59, 61, 67, 0.5),
    0 -0.25rem 1.5rem rgba(17, 44, 78, 1) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(22, 55, 95, 1) inset;
}
@media screen and (max-width: 600px) {
  .productionStatisticsHome,
  .clr {
    width: 9.5em;
    font-size: 3em;
  }
}
.productionStatisticsHome span,
.clr span {
  color: transparent;
  background-image: linear-gradient(0deg, #a5a5a5 0%, #fefafd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(291, 5%, 28%, 1));
}

.productionStatisticsHome div {
  font-size: 1rem;
  color: #f0de3a;
  position: absolute;
  top: 10px;
  left: 50px;
  animation: pulsate 3s infinite;
  -webkit-animation: pulsate 3s infinite; /* Safari and Chrome */
}
@keyframes pulsate {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
@-webkit-keyframes pulsate {
  /* Safari and Chrome */
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
.productionStatisticsHome::before,
.clr::before {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.productionStatisticsHome::after,
.clr::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.myAgentHome {
  margin-top: 14px;
}

.myAgentHome,
.clrHome {
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  width: 11em;
  height: 4.5em;
  padding: 3rem 2rem;
  border-radius: 15px;
  line-height: 110%;
  font-size: 2.25em;
  font-weight: 600;

  border: 1px solid rgba(1, 40, 128, 0);
  background-image: linear-gradient(-180deg, #36608a 0%, rgb(12, 33, 58) 100%);
  box-shadow: 0 1rem 1.25rem 0 rgba(59, 61, 67, 0.5),
    0 -0.25rem 1.5rem rgba(17, 44, 78, 1) inset,
    0 0.75rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(22, 55, 95, 1) inset;
}
@media screen and (max-width: 600px) {
  .myAgentHome,
  .clrHome {
    width: 9.5em;
    font-size: 3em;
  }
}
.myAgentHome span,
.clrHome span {
  color: transparent;
  background-image: linear-gradient(0deg, #a5a5a5 0%, #fefafd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(291, 5%, 28%, 1));
}
.myAgentHome::before,
.clrHome::before {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  opacity: 0.7;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.myAgentHome::after,
.clrHome::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.contractRequestHome {
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  position: relative;
  width: 11em;
  height: 4.5em;
  padding: 3rem 2rem;
  border-radius: 15px;
  line-height: 110%;
  font-size: 2.25em;
  font-weight: 600;

  border: 1px solid rgba(1, 40, 128, 0);
  background-image: linear-gradient(
    -190deg,
    rgb(144, 173, 192) 0%,
    #0a3e79 85%
  );
  box-shadow: 0 1rem 1.25rem 0 rgba(59, 61, 67, 0.5),
    0 -0.25rem 1.5rem rgba(17, 44, 78, 1) inset,
    0 0.25rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(22, 55, 95, 1) inset;
}
.disabledContractRequestHome {
  cursor: pointer;
  font-family: "Roboto Condensed", sans-serif;
  color: #eee;
  position: relative;
  width: 11em;
  height: 4.5em;
  padding: 3rem 2rem;
  border-radius: 15px;
  line-height: 110%;
  font-size: 2.25em;
  font-weight: 600;
  border: 1px solid rgba(1, 40, 128, 0.6);
  background-image: linear-gradient(-190deg, rgb(94, 112, 124) 0%, #0a3e79 85%);
  box-shadow: 0 1rem 1.25rem 0 rgba(59, 61, 67, 0.5),
    0 -0.25rem 1.5rem rgba(17, 44, 78, 1) inset,
    0 0.25rem 0.5rem rgba(255, 255, 255, 0.4) inset,
    0 0.25rem 0.5rem 0 rgba(22, 55, 95, 1) inset;
}
.disabledContractRequestHome:focus,
.disabledContractRequestHome:active,
.disabledContractRequestHome:visited {
  outline: none;
}
@media screen and (max-width: 600px) {
  .contractRequestHome,
  .clr {
    width: 9.5em;
    font-size: 3em;
  }
}
.contractRequestHome span,
.clr span {
  color: transparent;
  background-image: linear-gradient(0deg, #acacac 0%, #fefafd 100%);
  -webkit-background-clip: text;
  background-clip: text;
  filter: drop-shadow(0 2px 2px hsla(291, 5%, 28%, 1));
}
.contractRequestHome::before,
.clr::before {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.6;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.disabledContractRequestHome::before {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  top: 0.5rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}

.contractRequestHome::after {
  content: "";
  display: block;
  height: 0.25rem;
  position: absolute;
  bottom: 0.75rem;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 2.5rem);
  background: #fff;
  border-radius: 100%;

  filter: blur(1px);
  opacity: 0.05;
  background-image: linear-gradient(
    -270deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 20%,
    #ffffff 80%,
    rgba(255, 255, 255, 0) 100%
  );
}
.disabledContractRequestHome::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #000;
  border-radius: 15px;
  opacity: 0.5;
}
