.dataGridStyle {
  height: 325px;
  width: "auto";
}

.card {
  background: linear-gradient(to bottom right, white, #0e76bc) !important;
  min-height: fit-content;
}
.typography {
  color: #003760;
}       
      
.table {
  padding: 10px;
}
.input {
  margin-left: 8px;
  flex: 1;
}
.iconButton {
  padding: 10px;
}
.divider {
  height: 34px;
  width: 3px;
  background-color: grey;
}
.paper {
  height: 510px;
}
