.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

button,
a {
  outline: none !important;
}
